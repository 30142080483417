/**
 * Product Items
 */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deviceWidth } from '@/utils/deviceWidth';
import CartButton from '../AtomicComponents/CartButton';
import { getStoreType } from '@/utils/getStoreType';
import {
  fetchCatalogItemById,
  pushEventsLog,
  saveCart,
  saveProductLoaderId,
  setSelectedVariantModalVisibility,
  setVariantModalVisibility,
  openProductPersonalizationPopUp,
  savePDPData,
} from '@/redux/actions';
import { getRoute, PATH } from '@/utils/routes';
import {
  MAX_MOBILE_WIDTH,
  PRODUCT_NAME_REGEX,
  STORE_CUSTOMIZATION,
} from '@/utils/constants';
import { mutateItemObjectWithCartData } from '@/utils/mutateItemObjectWithCartData';
import { mutateVariantItemWithCartData } from '@/utils/mutateVariantItemWithCartData';
import { getDiscountPercentage } from '@/utils/getDiscountPercentage';
import { useSSRSelector } from '@/redux/ssrStore';
import { useCartHandlers } from '@/hooks/useCartHandler';

export default function ProductItem(props) {
  const {
    name,
    price,
    image_url,
    discounted_price,
    id,
    quantity,
    available,
    item_schedule,
  } = props.data || {};

  const {
    handleSetProductData,
    handleIncreamentClick,
    handleDecreamentClick,
    handleSetProductCustomizationCount,
  } = useCartHandlers();

  const [discountPercent, setDiscountPercent] = useState(0);
  const [listDiscriptionExpanded, setlistDiscriptionExpanded] = useState(false);
  const { userData, cartData } = useSelector((state) => ({
    userData: state.userReducer,
    cartData: state.cartReducer,
  }));
  const { storeData, oosStatus } = useSSRSelector((state) => {
    const storeData = state.storeReducer.store;
    return {
      storeData,
      oosStatus: storeData?.services?.hide_out_of_stock,
    };
  });

  const dispatch = useDispatch();
  const isB2bStore = getStoreType(storeData) === STORE_CUSTOMIZATION.B2B;

  useEffect(() => {
    const percent = getDiscountPercentage(price, discounted_price);
    setDiscountPercent(percent ? percent : 0);
  }, []);

  const toggleListItemDiscription = () => {
    setlistDiscriptionExpanded(!listDiscriptionExpanded);
  };

  const handleAddRemoveItem = (type) => {
    dispatch(
      pushEventsLog({
        event_name: 'Cx_ATC',
        data: {
          store_id: storeData?.store_id,
          domain: storeData?.store_info?.domain,
          page: window.location.href,
          device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
          cx_number: userData?.data?.phone,
        },
      })
    );
    if (props.isCartItem && props.variant) {
      const newCart = mutateVariantItemWithCartData(
        type,
        props.data,
        props.variant,
        cartData
      );
      dispatch(saveCart(newCart));
      return;
    }

    /** Check if variant present in this item */
    if (props.data?.variants_count > 0) {
      dispatch(saveProductLoaderId(props.data?.id));

      /** find the item index in cart */
      const itemIdxInCart = cartData.items.findIndex((el) => el.id === props.data?.id);
      /** check if item exist in cart */
      if (itemIdxInCart !== -1) {
        switch (type) {
          case 'inc':
            dispatch(setVariantModalVisibility(true));
            break;
          case 'dec':
            dispatch(setSelectedVariantModalVisibility(true));
            break;
        }
      } else {
        /** get the details item for this item id */
        dispatch(
          fetchCatalogItemById(
            { store_id: storeData.store_id, item_id: props.data?.id },
            true,
            (data) => {
              handleSetProductData(data);
            }
          )
        );
      }
    } else {
      if (props?.data?.product_personalization) {
        handleSetProductCustomizationCount(props?.data);
        handleSetProductData(props?.data);
        dispatch(savePDPData(props?.data));
        switch (type) {
          case 'add':
            dispatch(openProductPersonalizationPopUp());
            break;
          case 'inc':
            handleIncreamentClick(type, props?.data);
            break;
          case 'dec':
            handleDecreamentClick(type, props?.data);
            break;
          default:
            break;
        }
      } else {
        const newState = mutateItemObjectWithCartData(type, props.data, cartData);
        dispatch(saveCart(newState));
      }
    }
  };

  const renderName = () => {
    return props.data?.available ? (
      <a
        style={{
          textDecoration: 'none',
        }}
        className={`${props.isCardType ? 'item-name' : 'item-name-list mt0'} ph2`}
        href={getRoute(
          PATH.productDescription(id, name.replace(PRODUCT_NAME_REGEX, '-')),
          storeData?.store_info?.domain
        )}
      >
        {name}
      </a>
    ) : (
      <p className={`${props.isCardType ? 'item-name' : 'item-name-list mt0'} ph2`}>
        {name}
      </p>
    );
  };

  const renderProductImage = () => {
    return (
      <div
        className="catalog-tem-img relative"
        onClick={() => {
          props.onItemClick && props.onItemClick();
        }}
      >
        <img src="/assets/images/icons8-info.png" className="info-icon" alt="info icon" />
        <img
          src={image_url ? image_url : '/assets/images/img-icon-green.png'}
          alt="catalog item Image"
          className="image"
          loading="lazy"
        />
        <div className="discountInfo-chip">
          {props.data?.available !== 0 && discountPercent !== 0 && (
            <span>{discountPercent}% Off</span>
          )}
        </div>
      </div>
    );
  };

  const renderProductPrice = () => {
    if (isB2bStore) {
      return (
        <span className="discounted-price">
          {props?.data?.b2b_pricing_info?.price_range || 'Price on Request'}
        </span>
      );
    }
    return (
      <div className="price">
        {discounted_price < price ? (
          <>
            <span className="discounted-price">
              {'₹'}
              {renderDiscountPrice()}
            </span>{' '}
            <span className="amount-price">
              {' '}
              {'₹'}
              {renderNormalPrice()}
            </span>
          </>
        ) : (
          `₹${renderNormalPrice()}`
        )}{' '}
        {!props.isCardType && discountPercent > 0 && (
          <span className="percent-off">{discountPercent}% Off</span>
        )}
      </div>
    );
  };

  const renderDiscountPrice = () => {
    return props.isCartItem ? discounted_price * (quantity || 1) : discounted_price;
  };

  const renderNormalPrice = () => {
    return props.isCartItem ? price * (quantity || 1) : price;
  };

  const renderATCButton = () => {
    if (isB2bStore) return null;
    const itemIndexInCart = cartData.items.findIndex(
      (el) => (el.id || el.item_id) === id
    );
    if (storeData?.services?.store_flag === 0) return null;

    return (
      <CartButton
        productItem={cartData.items[itemIndexInCart]}
        quantity={itemIndexInCart >= 0 ? cartData.items[itemIndexInCart].quantity : 0}
        onChange={(change) => handleAddRemoveItem(change)}
        fontSize={14}
        hasScheduler={!!item_schedule}
      />
    );
  };

  const renderImageItem = () => (
    <>
      <div className={`top-wrap`}>
        {renderProductImage()}
        {renderName()}
      </div>
      <div className={`bottom-wrap with-image-item`}>
        {available === 1 ? (
          <div className="flex justify-between items-center ph2">
            <div className="left-wrap">
              <span>{renderProductPrice()}</span>
            </div>
            <div className="right-wrap ">
              {renderATCButton()}
              {props.data?.variants_count > 0 && (
                <div className="variant--count-icontext">
                  <img src="/assets/images/optionsMultiple.png"></img>
                  {props.data?.variants_count} options
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center ph2 black-50 fw6 f7 pv2">
            Out Of Stock
          </div>
        )}
      </div>
    </>
  );

  const productItemImage = () => (
    <div className="item-image">
      <img src={props.data?.thumbnail_url || props.data?.image_url} alt={name} />
    </div>
  );

  const renderSearchItem = () => (
    <div className="bottom-wrap without-image-item flex justify-between">
      <div
        className="w-70 flex flex-column justify-between"
        style={{ borderBottom: '1px dashed #bfbfbf' }}
      >
        <div>
          {renderName()}
          {renderItemDescription()}
        </div>
        <div className="pb3 pl2">{renderProductPrice()}</div>
      </div>
      <div className="right-wrap relative">
        {productItemImage()}
        {available === 1 ? (
          <div className="btn-comp">
            {renderATCButton()}
            {props.data?.variants_count > 0 && (
              <div className="variant--count-icontext">
                <img src="/assets/images/optionsMultiple.png"></img>
                {props.data?.variants_count} options
              </div>
            )}
          </div>
        ) : (
          <div className="flex justify-center items-end ph2 black-50 fw6 f7 pb3 pt1">
            Out Of Stock
          </div>
        )}
      </div>
    </div>
  );

  const renderItemDescription = () => {
    const availableClass = props.data?.available ? '' : ' outofstock';
    return props.data?.available !== 0 && props.data?.description !== '' ? (
      <div
        className={`catalog-item-description${availableClass}`}
        onClick={toggleListItemDiscription}
      >
        {!listDiscriptionExpanded &&
        props.data?.description &&
        props.data?.description.length > 60 ? (
          <div className={`catalog-item-description-text${availableClass}`}>
            {props.data?.description.substr(0, 56)}...
            <span
              className={`pointer catalog-item-description-readmore${availableClass}`}
              style={{
                color: storeData?.theme?.colors?.primary_color,
              }}
            >
              Read More+
            </span>
          </div>
        ) : (
          <div className={`catalog-item-description-text${availableClass}`}>
            {props.data?.description}
          </div>
        )}
      </div>
    ) : null;
  };

  return !available && oosStatus ? null : props.isDesktop ? (
    <article className="product-item-desktop">
      {image_url && props.isCartItem === false && (
        <div
          className="product-image"
          style={{
            backgroundImage: `url(${image_url})`,
            backgroundSize: 'cover',
          }}
          onClick={() => {
            props.onItemClick && props.onItemClick();
          }}
        >
          {discountPercent !== 0 && <span>{discountPercent}% Off</span>}
          <img src="/assets/images/icons8-info.png" alt="info icon" />
        </div>
      )}
      <div className={`product-info ${available === 0 && 'o-50'}`}>
        <div className="product-info__name">{name}</div>
        {props.isCartItem === false && renderItemDescription()}
        <div className={`product-info__options`}>
          <span>{renderProductPrice()}</span>
          {available === 1 ? (
            <span>{renderATCButton()}</span>
          ) : (
            <div className="flex justify-center items-center ph2 black-50 fw6 f7 pv2">
              Out Of Stock
            </div>
          )}
        </div>
      </div>
    </article>
  ) : (
    <div
      className={`product-item${props.isCardType ? ' card-type' : ''}${
        available === 0 ? ' o-60' : ''
      }${props.isCartItem ? ' product-cart-type' : ''}`}
    >
      {props.isCartItem ? (
        <>
          <div className="left-wrap">
            {renderName()}
            {renderProductPrice()}
          </div>
          <div className="right-wrap">{renderATCButton()}</div>
        </>
      ) : props.isCardType ? (
        // render cards
        renderImageItem()
      ) : (
        // render search
        renderSearchItem()
      )}
    </div>
  );
}
